<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <a-card>
          <template slot="title">
            <strong>Pencairan</strong>
          </template>
          <div class="row pl-3 pr-3 pt-3 pb-3">
            <!-- <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
              <label></label>
            </div> -->
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <a-table
                :columns="table.tablebawah.column"
                :dataSource="table.tablebawah.datatable"
                :pagination="false"
                size="small"
                :customRow="customRowB"
                  :expandIconAsCell="false"
                  :defaultExpandAllRows="true"
                  :expandIconColumnIndex="3"
              >
                <div
                  slot="filterDropdown"
                  slot-scope="{
                    setSelectedKeys,
                    selectedKeys,
                    confirm,
                    clearFilters,
                    column,
                  }"
                  style="padding: 8px;"
                >
                  <a-input
                    v-ant-ref="(c) => (searchInput = c)"
                    :placeholder="`Search ${column.title}`"
                    :value="selectedKeys[0]"
                    style="width: 188px; margin-bottom: 8px; display: block;"
                    @change="
                      (e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    "
                    @pressEnter="
                      () =>
                        handleSearch(selectedKeys, confirm, column.dataIndex)
                    "
                  />
                  <a-button
                    type="primary"
                    icon="search"
                    size="small"
                    style="width: 90px; margin-right: 8px;"
                    @click="
                      () =>
                        handleSearch(selectedKeys, confirm, column.dataIndex)
                    "
                  >
                    Search
                  </a-button>
                  <a-button
                    size="small"
                    style="width: 90px;"
                    @click="() => handleReset(clearFilters)"
                  >
                    Reset
                  </a-button>
                </div>
                <a-icon
                  slot="filterIcon"
                  slot-scope="filtered"
                  type="search"
                  :style="{ color: filtered ? '#108ee9' : undefined }"
                ></a-icon>
                <template
                  slot="customRender"
                  slot-scope="text, record, index, column"
                >
                  <span
                    v-if="searchText && searchedColumn === column.dataIndex"
                  >
                    <template
                      v-for="(fragment, i) in text
                        .toString()
                        .split(
                          new RegExp(
                            `(?<=${searchText})|(?=${searchText})`,
                            'i'
                          )
                        )"
                    >
                      <mark
                        v-if="
                          fragment.toLowerCase() === searchText.toLowerCase()
                        "
                        :key="i"
                        class="highlight"
                        >{{ fragment }}</mark
                      >
                      <template v-else>{{ fragment }}</template>
                    </template>
                  </span>
                  <template v-else>
                    {{ text }}
                  </template>
                </template>
                <p slot="expandedRowRender" slot-scope="record" style="margin: 0">
                  <strong>Alamat: </strong>{{ record.alamat }}
                </p>
                <span slot="sudah_cair" slot-scope="text">
                  <a-tag v-if="text" color="blue">Sudah Cair</a-tag>
                  <a-tag v-else color="red">Belum Cair</a-tag>
                  <!-- {{ text ? "Sudah Cair" : "Belum Cair" }} -->
                </span>
                <span slot="nominal" slot-scope="text">
                  {{ formatCurrency(text) }}
                </span>
              </a-table>
            </div>
          </div>
        </a-card>
      </div>
    </div>
    <a-modal v-model="formvisible" :width="700" title="Pencairan Kredit" :dialogStyle="{ top: '20px' }">
      <template slot="footer">
        <a-button class="btn btn-secondary" v-if="!maskInput.sudah_cair" @click="formvisible = !formvisible">Cancel</a-button>
        <a-button class="btn btn-primary" v-if="!maskInput.sudah_cair" @click="handleSubmit">Cairkan Kredit</a-button>
        <strong v-else>Data sudah dicairkan</strong>
      </template>
      <div class="row">
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
          <label class="mt-1">Rekening</label>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9">
          <a-input
            style="width: 50%;"
            placeholder="Rekening"
            v-model="maskInput.rekening"
            :readOnly="true"
          ></a-input>
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3 mt-1">
          <label class="mt-1">Nama</label>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9 mt-1">
          <a-input
            style="width: 50%;"
            placeholder="Nama"
            v-model="maskInput.nama"
            :readOnly="true"
          ></a-input>
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3 mt-1">
          <label class="mt-1">Alamat</label>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9 mt-1">
          <a-input
            style="width: 50%;"
            placeholder="Alamat"
            v-model="maskInput.alamat"
            :readOnly="true"
          ></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Rekening Tabungan</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            placeholder="Rekening Tabungan"
            v-model="maskInput.rekeningtabungan"
            style="width: 80%;"
            :readOnly="true"
          ></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Nama Tabungan</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            placeholder="Nama Tabungan"
            v-model="maskInput.nama_tabungan"
            style="width: 80%;"
            :readOnly="true"
          ></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Jangka Waktu</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            placeholder="Jangka Waktu"
                  style="width: 70px;text-align: right;"
            v-model="maskInput.jw"
            :readOnly="true"
          ></a-input>
          Bulan
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Suku Bunga</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            placeholder="Suku Bunga"
            v-model="maskInput.sukubunga"
            style="width: 80%;"
            :readOnly="true"
          ></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Plafond</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            placeholder="Plafond"
            v-model="maskInput.plafond"
            style="width: 80%;"
            :readOnly="true"
          ></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Biaya Administrasi</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            placeholder="Biaya Administrasi"
            v-model="maskInput.biaya_administrasi"
            style="width: 80%;"
            :readOnly="true"
          ></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Biaya Materai</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            placeholder="Biaya Materai"
            v-model="maskInput.biaya_materai"
            style="width: 80%;"
            :readOnly="true"
          ></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Biaya Notaris</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            placeholder="Biaya Notaris"
            v-model="maskInput.biaya_notaris"
            style="width: 80%;"
            :readOnly="true"
          ></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Biaya Asuransi</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            placeholder="Biaya Asuransi"
            v-model="maskInput.biaya_asuransi"
            style="width: 80%;"
            :readOnly="true"
          ></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Cara Pencairan</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-select class="" style="width: 200px;" v-model="maskInput.cara_pencairan">
            <a-select-option v-for="(data, index) in allMaster.carapencairan" :key="index" :value="data.id">{{ data.keterangan }}</a-select-option>
          </a-select>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment'
import * as lou from '@/services/data/lou'
import { mapState } from 'vuex'

export default {
  computed: mapState(['settings', 'user']),
  watch: {
    // 'settings.selectedRekening'() {
    //   this.checkRekening()
    // },
  },
  data() {
    return {
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      input: { rekening: '' },
      maskInput: {
        faktur: '',
        cabang: '01',
        rekcabang: '01',
        reregister: '',
        rerekening: '',
        rerekening1: '',
        namanasabah: '',
        alamatnasabah: '',
        telepon: '',
        pekerjaan: '',
        noregister: '',
        alamat: '',
        cara_pencairan: '',
        jw: '',
        nama: '',
        nama_tabungan: '',
        plafond: '',
        biaya_administrasi: '',
        biaya_asuransi: '',
        biaya_materai: '',
        biaya_notaris: '',
        rekening: '',
        rekeningtabungan: '',
        sudah_cair: '',
        sukubunga: '',
      },
      allMaster: {
        carapencairan: [],
      },

      // Modal Property
      formvisible: false,
      carinasabahtitle: 'Cari',
      inputModal: {
        nama: '',
        alamat: '',
        cabang: '',
      },
      table: {
        tableatas: {
          column: [
            {
              title: 'Kode',
              rowKey: 'kode',
              dataIndex: 'kode',
              scopedSlots: { customRender: 'kode' },
              width: 100,
            },
            {
              title: 'Nama',
              dataIndex: 'nama',
              scopedSlots: { customRender: 'nama' },
              width: 250,
            },
            // {
            //   title: 'KTP',
            //   dataIndex: 'ktp',
            //   scopedSlots: { customRender: 'ktp' },
            //   width: 100,
            // },
            {
              title: 'Alamat',
              dataIndex: 'alamat',
              scopedSlots: { customRender: 'alamat' },
              // width: 100,
            },
          ],
          datatable: [],
        },
        tablebawah: {
          column: [
            {
              title: 'Status',
              dataIndex: 'sudah_cair',
              scopedSlots: { customRender: 'sudah_cair' },
              width: 80,
            },
            {
              title: 'Rekening',
              dataIndex: 'rekening',

              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'customRender',
              },
              onFilter: (value, record) =>
                record.rekening
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase()),
              onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
              // width: 100,
            },
            {
              title: 'Nama',
              dataIndex: 'nama',

              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'customRender',
              },
              onFilter: (value, record) =>
                record.nama
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase()),
              onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
              width: 200,
            },
            {
              title: 'Rekening Tabungan',
              dataIndex: 'rekeningtabungan',

              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'customRender',
              },
              onFilter: (value, record) =>
                record.rekeningtabungan
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase()),
              onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
              // width: 100,
            },
            // {
            //   title: 'Alamat',
            //   dataIndex: 'alamat',
            //   scopedSlots: {
            //     filterDropdown: 'filterDropdown',
            //     filterIcon: 'filterIcon',
            //     customRender: 'customRender',
            //   },
            //   onFilter: (value, record) =>
            //     record.alamat
            //       .toString()
            //       .toLowerCase()
            //       .includes(value.toLowerCase()),
            //   onFilterDropdownVisibleChange: (visible) => {
            //     if (visible) {
            //       setTimeout(() => {
            //         this.searchInput.focus()
            //       }, 0)
            //     }
            //   },
            //   // width: 100,
            // },
            {
              title: 'Jangka Waktu',
              dataIndex: 'jw',

              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'customRender',
              },
              onFilter: (value, record) =>
                record.jw
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase()),
              onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
              // width: 100,
            },
            {
              title: 'Plafond',
              dataIndex: 'plafond',

              scopedSlots: { customRender: 'nominal' },
            },
            {
              title: 'Suku Bunga',
              dataIndex: 'sukubunga',

              scopedSlots: { customRender: 'nominal' },
            },
            {
              title: 'Biaya Administrasi',
              dataIndex: 'biaya_administrasi',

              scopedSlots: { customRender: 'nominal' },
            },
            {
              title: 'Biaya Asuransi',
              dataIndex: 'biaya_asuransi',

              scopedSlots: { customRender: 'nominal' },
            },
            {
              title: 'Biaya Materai',
              dataIndex: 'biaya_materai',

              scopedSlots: { customRender: 'nominal' },
            },
            {
              title: 'Biaya Notaris',
              dataIndex: 'biaya_notaris',

              scopedSlots: { customRender: 'nominal' },
            },
          ],
          datatable: [],
        },
      },
    }
  },
  created() {
    this.getAllData()
  },
  methods: {
    moment,
    async showModal() {
      this.carinasabahvisible = true
      var res = await lou.customUrlGet2('pencairan_kredit')
      if (res) {
        console.log('res', res)
        this.table.tablebawah.datatable = res.data
      }
    },
    async handleSubmit() {
      var res = await lou.customUrlPost2('pencairan_kredit', {
        id: this.maskInput.id,
        cara_pencairan: this.maskInput.cara_pencairan,
      })
      if (res) {
        this.formvisible = false
        this.getAllData()
      }
    },
    async checkRekening() {
      this.input.rekening = this.settings.selectedRekening
      var text = this.settings.selectedRekening
      this.maskInput.rekcabang = text.slice(0, text.indexOf('.'))
      this.maskInput.rerekening = text.slice(
        text.indexOf('.') + 1,
        text.indexOf('.') + 3,
      )
      var ntext = text.replace('.', '')
      // console.log('ntext', ntext.slice(ntext.indexOf('.') + 1, ntext.length))
      this.maskInput.rerekening1 = ntext.slice(
        ntext.indexOf('.') + 1,
        ntext.length,
      )
      // this.rekeningcheck()
    },
    async rekeningcheck() {
      var res = await lou.customUrlGet2(
        'data_kredit/rekening/' +
          this.maskInput.rekcabang +
          '.' +
          this.maskInput.rerekening +
          '.' +
          this.maskInput.rerekening1,
      )
      if (res) {
        if (res.data !== null) {
          lou.shownotif('Ditemukan!', 'Rekening ditemukan.')
        } else {
          lou.shownotif(
            'Tidak ditemukan!',
            'Rekening tidak ditemukan.',
            'error',
          )
        }
      }
    },
    async getAllData() {
      var indexeddb = await lou.getDataFromDb()
      // console.log('indexeddb', indexeddb)
      // console.log('this.settings.whatapp', this.settings.whatapp)
      var allmaster = {}
      var masterIndex = indexeddb.findIndex(
        (x) =>
          x.mykey ===
          'allmaster' +
            this.settings.whatapp.appName.toLowerCase() +
            this.settings.whatapp.id,
      )
      // console.log('masterIndex', indexeddb[masterIndex])
      if (masterIndex < 0) {
        var res1 = await lou.customUrlGet2('master', false, true)
        var master = {
          mykey:
            'allmaster' +
            this.settings.whatapp.appName.toLowerCase() +
            this.settings.whatapp.id,
          data: JSON.stringify(res1.data),
        }
        await lou.addDataToDb(master)
        var nindexeddb = await lou.getDataFromDb()
        var targetIndex = nindexeddb.findIndex(
          (x) =>
            x.mykey ===
            'allmaster' +
              this.settings.whatapp.appName.toLowerCase() +
              this.settings.whatapp.id,
        )
        // console.log('nindexeddb', nindexeddb[targetIndex])
        allmaster = JSON.parse(nindexeddb[targetIndex].data)
      } else {
        allmaster = JSON.parse(indexeddb[masterIndex].data)
      }
      var cabanglist = []
      for (let u = 0; u < this.user.cabangList.length; u++) {
        const element = this.user.cabangList[u]
        // console.log('element', element)
        cabanglist.push({ kode: element.kode, keterangan: element.kode })
      }
      // console.log('indexeddb', indexeddb)
      this.allMaster = allmaster
      // console.log('this.allMaster', this.allMaster)
      var res = await lou.customUrlGet2('pencairan_kredit')
      if (res) {
        this.table.tablebawah.datatable = res.data
      }
    },
    // Modal function
    clearRekening() {
      this.settings.selectedRekening = ''
    },
    async findData() {
      var imbuhan = ''
      imbuhan +=
        this.inputModal.nama !== '' ? '?Nama=' + this.inputModal.nama : ''
      imbuhan +=
        this.inputModal.alamat !== ''
          ? this.inputModal.nama !== ''
            ? '&Alamat=' + this.inputModal.alamat
            : '?Alamat=' + this.inputModal.alamat
          : ''
      imbuhan +=
        this.inputModal.cabang !== ''
          ? '&cabang=' + this.inputModal.cabang
          : ''
      var res = await lou.customUrlGet2('carinasabah' + imbuhan)
      if (res) {
        this.table.tableatas.datatable = res.data
        // for (let i = 0; i < 20; i++) {
        //   const element = res.data[0]
        //   this.table.tableatas.datatable.push(element)
        // }
      }
    },
    async nasabahDetail(record) {
      this.loading = true
      var res = await lou.customUrlGet2('carinasabah/detail/' + record.kode)
      if (res) {
        this.maskInput.cabang = record.kode.slice(0, record.kode.indexOf('.'))
        this.maskInput.reregister = record.kode.slice(
          record.kode.indexOf('.') + 1,
          record.kode.length,
        )
        // this.registeridchanged()
        this.table.tablebawah.datatable = res.data
        this.loading = false
      }
    },
    customRow(record, index) {
      return {
        on: {
          click: (event) => {
            // console.log('record', record)
            // console.log('index', index)
            // console.log('event', event)
            this.settings.selectedKodeRegister = record.kode
            this.nasabahDetail(record)
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
    customRowB(record, index) {
      return {
        on: {
          click: (event) => {
            console.log('record', record)

            this.input.rekening = record.rekening
            var text = record.rekening
            this.maskInput.rekcabang = text.slice(0, text.indexOf('.'))
            this.maskInput.rerekening = text.slice(
              text.indexOf('.') + 1,
              text.indexOf('.') + 3,
            )
            var ntext = text.replace('.', '')
            // console.log('ntext', ntext.slice(ntext.indexOf('.') + 1, ntext.length))
            this.maskInput.rerekening1 = ntext.slice(
              ntext.indexOf('.') + 1,
              ntext.length,
            )
            this.maskInput = this.$g.clone(record)
            this.maskInput.plafond = this.formatCurrency(record.plafond)
            this.maskInput.biaya_administrasi = this.formatCurrency(record.biaya_administrasi)
            this.maskInput.biaya_asuransi = this.formatCurrency(record.biaya_asuransi)
            this.maskInput.biaya_materai = this.formatCurrency(record.biaya_materai)
            this.maskInput.biaya_notaris = this.formatCurrency(record.biaya_notaris)
            // console.log('this.maskInput', this.maskInput)
            this.formvisible = true
            // this.settings.selectedRekening = record.rekening
            // console.log('index', index)
            // console.log('event', event)
            // console.log('this.settings.selectedRekening', this.settings.selectedRekening)
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
    formatCurrency(v) {
      return lou.curency(v)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    dateFormatter(params) {
      var ret =
        params.value === null
          ? ''
          : moment(params.value, 'YYYY-MM-DD').format('DD-MM-YYYY')
      // console.log('dateFormatter', ret)
      return ret !== 'Invalid date' ? ret : params.value
    },
    currencyFormatter(params) {
      var ret =
        params.value === null
          ? 'NaN'
          : lou.curency(parseFloat(params.value).toFixed(0))
      return ret === 'NaN' ? '' : ret
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
  },
}
</script>

<style lang="scss" scoped></style>
